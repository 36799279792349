<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2 iner">
      <div
        v-if="!ag"
        class="LoginForm text-center"
      >
        <img
          src="/warning.png"
          alt=""
          height="130"
          style="border-radius: 10px;"
        >
        <p
          class="text-center font-weight-bolder ml-3"
          style=" margin-top: -75px;"
        >
          URL ไม่ถูกต้อง กรุณาติดต่อผู้แนะนำ
        </p>
      </div>
      <!-- Login v1 -->
      <div
        v-else
        class="LoginForm"
      >
        <b-card
          class="mb-0"
        >
          <b-link class="brand-logo">
            <img
              src="Lo.png"
              alt="logo"
              height="75"
            >
          </b-link>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form"
              @submit.prevent="login"
            >

              <div>
                <label for="sasd">เบอร์โทรศัพท์</label>
                <div class="form-group-10 form-group has-feedback">

                  <div class="country-code dropdown">
                    <div
                      class="dropdown-title"
                    >
                      <i class="flag th" /><strong class="telcode">+66</strong>
                    </div>
                  </div>
                  <div class="tel-number">
                    <validation-provider
                      #default="{ errors }"
                      name="เบอร์โทรศัพท์"
                      rules="required|min:10"
                    >
                      <b-form-input
                        v-model="userEmail"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10"
                        placeholder="กรุณากรอกเบอร์โทรศัพท์"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] ? 'โปรดระบุหมายเลขโทรศัพท์ให้ถูกต้อง' : '' }}</small>
                    </validation-provider>
                  </div>
                  <div class="clearfix" />
                </div>
              </div>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                <!-- <b-link :to="`/forgot-password?ag=${$route.query.ag}`">
                  <small>ลืมรหัสผ่าน?</small>
                </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] ? 'โปรดระบุรหัสผ่านให้ถูกต้อง' : '' }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  จดจำรหัสผ่าน
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="gradient"
                block
                :disabled="invalid"
              >
                เข้าสู่ระบบ
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>หากยังไม่มีบัญชีผู้ใช้? </span>
            <b-link :to="`/register?ag=${$route.query.ag}`">
              <span>สร้างบัญชีผู้ใช้</span>
            </b-link>
          </b-card-text>

        <!-- <div class="divider my-2">
          <div class="divider-text">
            หรือ
          </div>
        </div> -->

        <!-- social button -->
        </b-card>
      </div>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ag: atob(this.$route.query.ag),
      status: '',
      password: '',
      userEmail: '',
      Ip: null,
      LottoList: [
        { text: 'กรุณาเลือกหวย', value: null, SubList: null },
        { text: 'หวยรัฐบาล', value: 'THG', SubList: null },
        {
          text: 'ธกส',
          value: 'BAAC',
          SubList: [
            { text: 'หวย ธกส.(BAAC1)', value: 'BAAC1' },
            { text: 'หวย ธกส.(BAAC2)', value: 'BAAC2' },
          ],
        },
      ],
      // sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.GetIp()
  },
  methods: {
    async GetIp() {
      try {
        const { data: res } = await axios.get('https://api.ipify.org')
        this.Ip = res
      } catch (e) {
        this.Ip = null
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              agent_id: this.ag,
              Detail: {
                Ip: this.Ip,
                UserAgent: navigator.userAgent,
                Platform: navigator.platform,
              },
            })
            .then(response => {
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('LottoList', JSON.stringify(this.LottoList))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit(
              //   'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
              //   userData.extras.eCommerceCartItemsCount,
              // )

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `มหาล๊อตโต ยินดีต้อนครับค่ะ  คุณ${
                        `${userData.fname} ${userData.sname}`
                        || userData.username
                      }`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '',
                    },
                  })
                })
            })
            .catch(error => {
              this.$bvToast.toast('เบอร์โทรหรือรหัสที่คุณป้อนไม่ถูกต้อง', {
                title: 'ล็อกอินไม่สำเร็จ',
                variant: 'danger',
                solid: true,
              })
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.gradient {
  border: none;
  background: rgb(34,82,154);
  background: linear-gradient(357deg, rgba(34,82,154,1) 0%, rgba(254,124,134,1) 100%) !important;
}
.form-control {
  height: 44px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
}
.iner {
  position: relative;
  z-index: 2;
}
.mainbg {
  background: rgb(58, 8, 8);
  background: linear-gradient(
    158deg,
    rgb(21, 31, 20) 0%,
    rgb(224, 218, 218) 48%,
    rgb(31, 118, 5) 96%
  );
  width: 100%;
  min-height: 100vh;
  left: 0;
  position: relative;
  z-index: 1;
  display: flex;
  text-align: inherit;
}
.mainbg:after,
.mainbg:before {
  content: '';
  width: 100%;
  position: absolute;
  z-index: 0;
}
.mainbg:before {
  background: url(/gradiant-top.svg) top no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  height: auto;
}
.mainbg:after {
  background: url(/gradiant-bottom.svg) bottom no-repeat;
  background-size: 60% auto;
  bottom: 0;
  height: 100vh;
}
.btn-facebook {
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(0, 102, 255);
}

.btn-line {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 0;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(81, 255, 0);
}

.btn-google {
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(255, 0, 0);
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
